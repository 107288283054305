<template>
  <div class="intro">
    <div class="container">
      <div class="alert alert-danger d-flex justify-content-between align-items-center mt-4" v-if="computedAlertShowing">
        <span>관리자 계정으로 로그인 해주세요.</span>
        <button class="btn btn-danger" @click="reLogin()">로그아웃</button>
      </div>
      <div class="section apply">
        <div class="subject">
          <b class="title">오마이컴퍼니에서<br/>쉽고 빠르게 프로젝트를 오픈하세요.</b>
        </div>
        <ul class="tight no-scrollbar">
          <li v-for="m in computedMenu" :key="`${component.name}ApplyMenu${m.name}`" :style="{backgroundColor: m.bgColor}">
            <div class="info">
              <span class="font-sm" :style="{color: m.mainColor}">{{ m.subTitle }}</span>
              <b class="title">{{ m.title }}</b>
              <span class="color-secondary font-sm ">{{ m.desc }}</span>
            </div>
            <div class="actions">
              <Anchor :href="b.link" class="btn font-sm" v-for="b in m.buttons" :key="`${component.name}ApplyMenuAction${b.name}`">
                <b>{{ b.title }}</b>
              </Anchor>
            </div>
            <span class="img" :style="{backgroundImage: `url(${m.img})`}"></span>
          </li>
        </ul>
      </div>
      <div class="section projects">
        <div class="subject">
          <b class="title">세상의 변화를 꿈꾸는 사람들이<br/>함께하는 곳, 오마이컴퍼니</b>
          <div class="color-secondary font-sm pt-3">오마이컴퍼니는 국내 대표적인 크라우드펀딩 플랫폼으로 나의 후원과 투자가 세상에 꼭 필요한 곳에 쓰여지길 바라는 여러분들과 함께 합니다.</div>
        </div>
        <ul class="tight">
          <li><span class="img" :style="{backgroundImage:'url(/assets/img/page.apply.intro.project1.jpg)'}" alt="프로젝트"></span></li>
          <li><span class="img" :style="{backgroundImage:'url(/assets/img/page.apply.intro.project2.jpg)'}" alt="프로젝트"></span></li>
          <li><span class="img" :style="{backgroundImage:'url(/assets/img/page.apply.intro.project3.jpg)'}" alt="프로젝트"></span></li>
        </ul>
      </div>
      <div class="section support">
        <div class="subject">
          <b class="title">당신을 위한 강력한 지원</b>
        </div>
        <ul class="tight">
          <li v-for="s in supports" :key="`${component.name}Support${s.name}`">
            <img :src="s.img" :alt="s.title">
            <div class="info">
              <b class="d-block">{{ s.title }}</b>
              <div class="desc font-sm"><span>{{ s.desc }}</span></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import env from "@/scripts/env";
import router from "@/scripts/router";
import Anchor from "@/components/Anchor.vue";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageApplyIntro";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.dispatch("setDocumentTitle", "프로젝트 신청");
    });

    const state = reactive({
      swiper: null,
    });

    const computedAlertShowing = computed(() => {
      return window.top !== window && !store.state.account.memberAuth.includes("ADMIN");
    });

    const computedMenu = computed(() => {
      return [
        {
          mainColor: "#EF6250",
          bgColor: "#fce6e3",
          subTitle: "개인, 단체, 기업 누구나",
          title: "후원형",
          name: "reward",
          desc: "신규 상품 및 서비스 개발, 공익 캠페인 등 다양한 프로젝트를 알리고 실행 자금을 모집합니다",
          img: "/assets/img/page.apply.intro.reward.png",
          buttons: [{
            link: "/apply/rewardIntro",
            title: "프로젝트 신청",
            name: "rewardProjectApply"
          }, {
            link: definitions.urls.rewardGuide,
            title: "가이드",
            name: "rewardProjectApplyGuide"
          },],
        },
        {
          mainColor: "#007bff",
          bgColor: "#d6eaff",
          subTitle: "기업(법인회원) 신청 가능",
          title: "증권형",
          name: "invest",
          desc: "우리 기업의 성장과 미래를 함께할 투자자를 모집합니다",
          img: "/assets/img/page.apply.intro.invest.png",
          buttons: [{
            link: "/apply/investIntro",
            title: "프로젝트 신청",
            name: "investProjectApply"
          }, {
            link: definitions.urls.investGuide,
            title: "가이드",
            name: "investProjectApplyGuide"
          },],
        },
        {
          mainColor: "#27CAA1",
          bgColor: "#dcf6f0",
          subTitle: "소상공인 신청 가능",
          title: "우리동네 크라우드펀딩",
          name: "wooridongne",
          desc: "대한민국의 소상공인을 응원합니다. 소상공인이라면 다양한 혜택이 제공되는 우리동네 펀딩에 도전하세요",
          img: "/assets/img/page.apply.intro.wooridongne.png",
          buttons: [{
            link: env.location === "prd" ? "/forms/6c76009af449" : "/forms/22a40c21d000",
            title: "후원형",
            name: "wooridongneInvestProjectApply"
          }, {
            link: env.location === "prd" ? "/forms/65ab71d53fde" : "/forms/5d60dc569d24",
            title: "증권형",
            name: "wooridongneRewardProjectApply"
          },],
        },
        {
          mainColor: "#FFA943",
          bgColor: "#fff1e1",
          subTitle: "기관, 기업, 단체 등 가능",
          title: "모의 투자",
          name: "simulation",
          desc: "내 아이디어의 실현 가능성을 모의투자를 통해 검증받습니다",
          img: "/assets/img/page.apply.intro.mock.png",
          buttons: [{
            link: "/apply/rewardIntro?mock",
            title: "프로젝트 신청",
            name: "simulationProjectApply"
          }],
        },
        {
          mainColor: "#EF6250",
          bgColor: "#e1e9ff",
          subTitle: "펀딩 진행자 누구나",
          title: "광고 서비스",
          name: "advertising",
          desc: "다양한 광고 서비스로 맞춤 타겟을 설정하여 효과적으로 메시지를 전달할 수 있어요",
          img: "/assets/img/page.apply.intro.advertising.png",
          buttons: [{
            link: "https://www.ohmycompany.com/forms/5d9660c56f75",
            title: "서비스 신청",
            name: "advertisingApply"
          }, {
            link: "https://corp.ohmycompany.com/6f3cfc43-45e9-4a97-b266-ff1a73f5531a",
            title: "소개서",
            name: "advertisingProjectApplyGuide"
          },],
        },
      ];
    });

    const supports = [
      {
        name: "help",
        title: "전문매니저의 도움",
        desc: "펀딩 신청을 완료하면 프로젝트 전문 매니저가 영업일 기준 2일 이내 빠른 피드백으로 프로젝트 오픈을 도와드립니다.",
        img: "/assets/img/page.apply.intro.support.help.svg",
      },
      {
        name: "making",
        title: "펀딩 페이지 제작 지원",
        desc: "펀딩 준비가 어려운 분들을 위한 펀딩멘토링, 페이지 디자인 및 제품 사진 촬영을 지원합니다.",
        img: "/assets/img/page.apply.intro.support.making.svg",
      },
      {
        name: "promotion",
        title: "손쉬운 프로젝트 홍보",
        desc: "오마이컴퍼니가 보유한 다양한 홍보 채널을 통해 프로젝트를 효과적으로 확산시켜드립니다.",
        img: "/assets/img/page.apply.intro.support.promotion.svg",
      },
      {
        name: "manage",
        title: "직관적인 관리 페이지",
        desc: "진행자에게 제공되는 관리 페이지를 통해 하루 평균 접속량, 후원자들의 응원, Q&A 등을 한 번에 관리하세요.",
        img: "/assets/img/page.apply.intro.support.manage.svg",
      },
      {
        name: "calculate",
        title: "쉽고 빠른 정산",
        desc: "펀딩 성공 시 명시된 시일 이후에 바로 정산 절차를 받아보실 수 있습니다. 모바일 정산 신청을 할 수 있도록 간소화된 절차를 통해 빠르게 정산을 받아보세요.",
        img: "/assets/img/page.apply.intro.support.calculate.svg",
      },
    ];

    // const openAI = () => {
    //   store.commit("openModal", {
    //     name: "ChatGPT",
    //     params: {
    //       page: "intro"
    //     }
    //   });
    // };

    const reLogin = async () => {
      await store.dispatch("logoutAccount", {
        afterLogout: () => router.replace(`/apply/${router.app.$route.query.projectSeq}`),
      });
    };

    return {component, computedAlertShowing, computedMenu, supports, reLogin};
  }
});
</script>

<style lang="scss" scoped>
.intro::v-deep {
  padding-bottom: $px72;

  .section {
    margin-top: $px72;

    .subject {
      margin-bottom: $px32;

      > .title {
        font-size: $px22;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: $px16;
      }
    }

    &.apply {
      > ul {
        display: flex;
        flex-wrap: wrap;
        gap: $px16;

        li {
          width: calc((100% - ($px16 * 2)) / 3);
          border-radius: $px24;
          padding: $px32;
          position: relative;
          height: auto;

          .info {
            display: flex;
            flex-direction: column;
            gap: $px16;
            height: $px138;
            margin-bottom: $px16;

            > .title {
              line-height: 1;
              font-weight: 700;
              font-size: $px22;
            }
          }

          .actions {
            display: flex;
            flex-direction: column;
            gap: $px8;
            width: fit-content;
            margin-bottom: $px72;

            > a {
              background-color: #fff;
              border-radius: $px16;
              padding: $px8 $px16;
            }
          }

          .img {
            position: absolute;
            bottom: $px32;
            right: $px32;
            width: $px138;
            height: $px100;
          }
        }
      }
    }

    &.projects {
      > ul {
        display: flex;
        gap: $px10;

        li {
          width: calc((100% - ($px10 * 2)) / 3);
          border-radius: $px16;
          overflow: hidden;
          height: $px200;

          > .img {
            width: 100%;
            height: 100%;
            background-size: cover;
          }
        }
      }
    }

    &.support {
      > ul {
        display: flex;
        flex-wrap: wrap;
        gap: $px48;

        li {
          width: calc((100% - ($px48 * 2)) / 3);

          > img {
            width: 100%;
            background-color: #f2f2f2;
            border-radius: $px24;
          }

          .info {
            padding: 0 $px12;

            b {
              padding-top: $px24;
            }

            .desc {
              padding-top: $px8;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    padding-bottom: $px48;

    .container .section {
      margin-top: $px48;

      &.apply {
        > ul {
          flex-wrap: initial;
          overflow: auto;
          margin: 0 -15px;

          li {
            width: calc(100% / 1.3);
            flex-shrink: 0;

            &:first-child {
              margin-left: 15px;
            }

            &:last-child {
              margin-right: 15px;
            }
          }
        }
      }

      &.projects {
        > ul {
          flex-direction: column;

          li {
            width: 100%;
          }
        }
      }

      &.support {
        > ul {
          flex-direction: column;

          li {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>